<template>
  <main>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="isBeforeMounted">
        <section class="firstTouch">
          <div class="firstTouch_container">
            <img src="@/assets/about/eye-catching-drop.png">
          </div>
        </section>
      </div>
      <div v-else>
        <section class="result_itemSuggestion_title">
          <div class="result_itemSuggestion_title_container">
            <h1>あなたにおすすめのメイク商品をトータルでご提案します！</h1>
            <div class="concierge_info">
              <img
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/'
                    + lowerConciergeName
                    + '.png'
                "
              >
              <div class="concierge_profile">
                <span>プロメイクアップアーティスト</span>
                <h3>
                  {{ conciergeName }}
                </h3>
                <router-link
                  class="profile_btn"
                  :to="{
                    name: 'Concierges',
                    params: { incomingGenre: 'makeup' },
                  }"
                >
                  プロフィールを見る
                </router-link>
              </div>
            </div>
          </div>
        </section>

        <section class="suggested_item_container">
          <div class="mappedAnswers">
            <p
              v-for="answer in mappedAnswers"
              :key="answer"
            >
              {{ answer }}
            </p>
          </div>

          <div
            v-for="segment in mappedCategories"
            :key="segment"
            class="suggested_item"
          >
            <div class="item_categories_btn_row">
              <category-square
                v-for="(cate, index) in segment"
                :key="cate.id"
                :class="{ is_center_square: index % 3 == 1 }"
                :category="cate"
                :selected_category="selected_category"
                @click="changeCategory(cate.name)"
              />
            </div>
            <div
              v-if="
                segment.map((x) => x.name).includes(selected_category) &&
                  filteredItems.length == 0
              "
              class="noItemCategory"
            >
              <div class="noItemDescription">
                <p>
                  お答えいただいた条件の場合、このカテゴリーでコンシェルジュおすすめの商品はありません。
                </p>
              </div>
            </div>
            <item-list
              v-if="segment.map((x) => x.name).includes(selected_category)"
              genre="makeup"
              :category="selected_category"
              :items="filteredItems"
            />
          </div>
        </section>

        <section class="guiding_section_container">
          <div class="guiding_section_inner">
            <div class="guiding_section_toChatbot">
              <router-link to="/flow/item-suggestions">
                もう一度コンシェルジュからオススメ商品の提案を受ける
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </main>
</template>
<script>
import CategorySquare from "../../components/CategorySquare.vue";
import ItemList from "../../components/ItemList.vue";
import axios from "axios";
import liff from "@line/liff";
const concierges = require("../../data/concierges");
const makeupCategories = require("../../data/makeupCategories");
const answerMapper = require("../../data/answerMapper");

export default {
  name: "MakeupItemResult",
  components: {
    CategorySquare,
    ItemList,
  },
  props: {
    id_token: {
      type: String,
      default: "",
    },
    result_set: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 回答マッピング用データ
      answerMapper,
      // 画像のルートURL
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      // コンシェルジュ情報
      conciergeName: concierges[3].name,
      lowerConciergeName: concierges[3].name_lower,
      // メジャー情報（固定）
      items: [],
      genre: "メイク",
      makeupCategories,
      selectedCategory: makeupCategories[0],
      itemPageNumber: 1,
      mappedAnswers: [],
      // モーダル部分
      isOpen: false,
      selectedTool: null,
      selected_category: "",
      isBeforeMounted: true,
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) => item.category == this.selected_category
      );
    },
    filtersItems() {
      return this.items.filter(
        (item) => item.category === this.selectedCategory.name
      );
    },
    mappedCategories() {
      const length = Math.ceil(this.makeupCategories.length / 3);
      return new Array(length)
        .fill()
        .map((_, i) => this.makeupCategories.slice(i * 3, (i + 1) * 3));
    },
  },
  mounted() {
    const target_url = process.env.VUE_APP_RESULT_URL + "item-suggestions";
    axios
      .post(target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        command: "get_result_makeup_products",
        id_token: this.id_token ? this.id_token : liff.getIDToken(),
        result_set: this.result_set,
      })
      .then((res) => {
        // メジャー情報
        this.items = res.data.Items;
        this.mappedAnswers = this.getMappedAnswers(
          "makeup",
          res.data.result_set
        );
        this.isBeforeMounted = false;
      })
      .catch((err) => {
        console.log("MakeupItemResult", err.code, err.message);
      });
  },
  methods: {
    changeCategory(category) {
      if(this.selected_category==category){
        this.selected_category=""
      }else if(this.selected_category==""){
        this.selected_category = category
        this.$gtag.event("view_item_list", {
        item_list_name: "Makeup Result",
        items: this.convertItems2GA4(this.filteredItems),
        });
      }else{
        const prevCategoryIndex = this.makeupCategories.map(x=>x.name).indexOf(this.selected_category)
        const newCategoryIndex = this.makeupCategories.map(x=>x.name).indexOf(category)
        if(Math.floor(prevCategoryIndex/3)<Math.floor(newCategoryIndex/3)){
          const itemList = document.getElementsByClassName("suggested_item_list")[0]
          window.scrollBy(0, -(itemList.clientHeight))
        }        
        this.selected_category = category;
        this.$gtag.event("view_item_list", {
          item_list_name: "Makeup Result",
          items: this.convertItems2GA4(this.filteredItems),
        });
      }
    },
    getMappedAnswers(genre, result_set) {
      let mappedAnswers = [];
      for (let qa of Object.entries(result_set)) {
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
    modalClose(e) {
      if (
        e.target.className == "makeupTools_modal" ||
        e.target.className == "makeupTools_modal_close"
      ) {
        this.isOpen = false;
      }
    },
    selectTool(tool, i) {
      this.selectedTool = tool;
      this.isOpen = true;
      this.viewToolGA4(tool, i);
    },
    isAffiliateUrlBlank(affiliateUrls) {
      if (
        (!("amazon" in affiliateUrls) || affiliateUrls.amazon == "") &&
        (!("rakuten" in affiliateUrls) || affiliateUrls.rakuten == "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    a8_affiliate_img(a8_url) {
      var url = new URL(a8_url);
      // URLSearchParamsオブジェクトを取得
      var params = url.searchParams;
      return "https://www15.a8.net/0.gif?a8mat=" + params.get("a8mat");
    },
    moshimo_affiliate_img(moshimo_url) {
      var url = new URL(moshimo_url);
      // URLSearchParamsオブジェクトを取得
      var params = url.searchParams;
      return (
        "https://i.moshimo.com/af/i/impression?a_id=" +
        params.get("a_id") +
        "&p_id=" +
        params.get("p_id") +
        "&pc_id=" +
        params.get("pc_id") +
        "&pl_id=" +
        params.get("pl_id")
      );
    },
    selectItemGA4(shopname, selected_item, i, is_tool) {
      var item = this.convertItems2GA4([selected_item]);
      item[0].index = i;
      item[0].promotion_name = shopname;
      if (is_tool) item[0].item_category3 = "メイク道具";
      this.$gtag.event("select_item", {
        item_list_name: "Makeup Items",
        items: item,
      });
    },
    convertItems2GA4(items) {
      return items.map((item, idx) => {
        return {
          item_id: item.item_id.toString(),
          item_name: item.item_name,
          item_brand: item.brand_name,
          item_category: "メイク",
          item_category2: this.selectedCategory.name,
          item_category3: item.subcategory,
          index: idx,
          affiliation: "Eri",
        };
      });
    },
    viewToolGA4(tool, i) {
      this.$gtag.event("view_item", {
        currency: "JPY",
        value:
          (tool.affiliate_links.amazon != "" || tool.affiliate_links != "") &&
          tool.official_price
            ? tool.official_price
            : 0,
        items: [
          {
            item_id: tool.item_id,
            item_name: tool.item_name,
            item_brand: tool.brand_name,
            item_category: "メイク",
            item_category2: this.selectedCategory.name,
            item_category3: "メイク道具",
            index: i,
            affiliation: "Eri",
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
main {
  position: relative;
}
h1 {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
}
a {
  text-decoration: none;
  color: #777777;
}
.result_itemSuggestion_title {
  background-color: #ffffff;
  padding: 0 40px;
}
.result_itemSuggestion_title_container {
  padding: 50px 0;
}
.result_itemSuggestion_title_container span {
  font-size: 24px;
}
.concierge_info {
  display: flex;
}
.concierge_info img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: #f29f8f solid 1px;
}
.concierge_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.concierge_profile span {
  font-size: 14px;
}
h3 {
  font-size: 22px;
  font-weight: bold;
  padding: 2px 0;
}
.profile_btn {
  padding: 11px 14px;
  background-color: #fadad2;
  text-align: center;
}
.suggested_item_container {
  background-color: #f5eae7;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px 12px;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
/* .suggested_item_info {
  padding: 30px 0 85px 0;
} */
.item_categories_btn_row {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  margin: 0 auto;
  max-width: 540px;
}
.item_categories_btn_container {
  position: relative;
}
.item_categories_btn_inner {
  width: 100%;
}
.item_categories_btn img {
  width: 60px;
  padding: 20px 0;
}
.item_categories_btn p {
  font-size: 14px;
}
.clearboth {
  clear: both;
}
/* .item_category_selected{
  font-weight: bold;
  font-size: 18px;
} */
.item_category {
  display: inline-block;
  padding: 20px 0 6px 0;
  font-size: 18px;
}
.item_category span {
  font-size: 18px;
}
.item_img_container {
  position: relative;
}
.item_img {
  width: 100%;
  object-fit: cover;
  padding: 20px 0;
}
.item_brand {
  font-size: 14px;
  padding-bottom: 6px;
}
.right_btn {
  position: absolute;
  height: 23px;
  top: 50%;
  right: 12px;
}
.item_name {
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
}
.item_purchase {
  text-align: center;
  padding-bottom: 12px;
}
.amazon,
.rakuten {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;
}
.amazon img,
.rakuten img {
  width: 40px;
}
.item_price {
  margin-left: 15px;
}
.purchase_btn {
  margin-left: 15px;
}
.item_price {
  font-family: "Brandon Grotesque";
  font-size: 24px;
}
.item_purchase a {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 18px;
  color: #ffffff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_price_btn {
  text-align: center;
}
.item_price_btn a {
  padding: 10px 20px;
  background-color: #f29f8f;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
}
.makeupTools_container {
  padding: 50px 0 100px 0;
}
.howToMakeup_container h2,
.makeupTools_container h2,
.makeupTools_modal p {
  font-size: 20px;
  padding: 7px 11px;
  border: #707070 solid 1px;
  display: inline-block;
  z-index: 10;
  /* box-shadow: 10px 10px #fadad2; */
  font-weight: bold;
}
.howToMakeup_container iframe {
  padding: 29px 0 94px 0;
  border: none;
}
.makeupTools {
  padding: 25px 0;
}
.makeupTools_inner {
  display: flex;
}
.makeupTools_img {
  width: 65px;
  border: #f29f8f solid 1px;
  border-radius: 50%;
  margin-right: 13px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
/* モーダル部分 */
.makeupTools_modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
}
.makeupTools_modal_container {
  position: relative;
  background-color: #f5eae7;
  padding: 0 40px;
}
.makeupTools_modal_inner {
  padding: 25px 0 25px 0;
}
.makeupTools_modal_close {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  float: right;
  display: block;
  cursor: pointer;
}
.makeupTools_modal_close_container {
  position: relative;
  width: 40px;
}
.makeupTools_modal_close_border_top {
  background-color: #707070;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  top: -6px;
  transform: translateY(6px) rotate(-20deg);
}
.makeupTools_modal_close_border_bottom {
  background-color: #707070;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  bottom: -8px;
  transform: translateY(-6px) rotate(20deg);
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
img,
embed,
object,
iframe {
  vertical-align: bottom;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.guiding_section_toChatbot p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
.itemList {
  width: 100%;
  height: 120px;
}
.is_center_square {
  margin-left: 10px;
  margin-right: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.firstTouch {
  background-color: #ffffff;
  padding: 0 40px;
}
.firstTouch_container {
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1 {
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img {
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
.noItemCategory{
  background-color: #ffffff;
}
.noItemDescription{
  padding: 0 40px;
  margin: 5px 0;
}
.noItemDescription p{
  padding: 20px 0;
}
</style>
